import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './HomePage.css'; // Import the CSS

const HomePage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [error, setError] = useState(null);
    const [chat_id, setChat_id] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null)
    const fetchMessages = async () => {
        try {
            const response = await axios.post('/api/getMessage', {}, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('auth')}`,
                },
            });
            setMessages(response.data.messages);
            setChat_id(response.data.chat_id);
        } catch (err) {
            if (err.message === "Request failed with status code 401") {
                window.location.href = "/"
            }
            setError(err.message);
        }
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const humanMessage = { text: input, sent_by: "human" };
            console.log(inputRef)
            inputRef.current.value = ""
            setMessages(oldMessages => [...oldMessages, humanMessage]);
            const response = await axios.post('/api/sendMessage', { text: input, chat_id: chat_id }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth')}`,
                },
            });
            setMessages(oldMessages => [...oldMessages, response.data.gptReply]);
            setInput('');
        } catch (err) {
            if (err.message === "Request failed with status code 401") {
                window.location.href = "/"
            }
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-900 rounded-none">
            <div className="flex flex-col w-10/12 h-screen px-4 py-8 bg-gray-900 rounded-none ">
                <h2 className="mb-3 text-xl font-semibold text-white dark:text-white">Doc GPT <span className="italic gradient-text">[Powered by GPT4]</span>
                    <span className="inline-block px-2 py-1 ml-2 text-xs text-white bg-red-600 rounded">BETA</span>
                </h2>
                <div className="mb-2  overflow-auto h-[75vh]  px-3 bg-gray-900 text-white rounded">
                    {messages && messages[0] && messages.map((message, index) => (
                        <div key={index} className={`mt-2 ${message.sent_by === "human" ? "text-right" : "text-left"}`}>
                            <p className={`inline-block px-4 py-2 rounded-xl max-w-2xl ${message.sent_by === "human" ? "bg-blue-500 text-white" : "bg-gray-700 text-gray-300"}`}>
                                {message.text}
                            </p>
                            <p className={`mt-1 text-sm ${message.sent_by === "human" ? "text-blue-300" : "text-gray-500"}`}>
                                {message.sent_by === "human" ? "You" : "AI"}
                            </p>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <form className="flex mt-6" onSubmit={sendMessage}>
                    <input
                        type="text"
                        value={input}
                        ref={inputRef}
                        onChange={e => setInput(e.target.value)}
                        className="w-full px-4 py-3 mr-3 text-gray-900 bg-white  rounded-lg focus:outline-none"
                        placeholder="Type your message..."
                        disabled={isLoading}
                    />
                    <button
                        className="px-4 py-2 text-white bg-blue-500 rounded-lg focus:outline-none"
                        disabled={isLoading}
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    );
};

export default HomePage;
