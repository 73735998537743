import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabase';
import axios from 'axios';
import 'animate.css/animate.min.css';

const LandingPage = () => {
    const checkSession = async () => {
        if (localStorage.getItem("auth")) {
            try {
                const response = await axios.get('/api/verify', { headers: { 'Authorization': localStorage.getItem("auth") } });
                console.log(response)
                window.location.href = "/home"
            } catch (error) {
                console.log("Invalid login")
                localStorage.clear()
            }

        }
    }
    useEffect(() => {
        checkSession()
    }, [])
    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-300 via-blue-100 to-white flex flex-col">
            <div className="flex-grow">
                <div className="py-16 px-4 sm:px-6 lg:px-20 flex items-center justify-center h-full">
                    <div className="max-w-4xl">
                        <h2 className="text-2xl font-extrabold tracking-tight sm:text-5xl animate__animated animate__fadeInDown">Welcome to MedAssistant</h2>
                        <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl animate__animated animate__fadeInUp">
                            Empowering physicians with AI-driven medical assistance. Save time, increase accuracy, and focus on what matters the most - your patients.
                        </p>
                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow animate__animated animate__bounceIn">
                                <Link to="/signup" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                                    Get started
                                </Link>
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3 animate__animated animate__bounceIn">
                                <Link to="/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                                    Log in
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-12 px-4 sm:px-6 lg:px-8 bg-indigo-700">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                        Trusted by top healthcare providers
                    </h2>
                    <p className="mt-3 text-sm text-indigo-200 sm:mt-4">
                        GPT-4, the latest iteration of OpenAI's Generative Pretrained Transformers, is a revolutionary AI model with enhanced capabilities in generating human-like text. Acquiring beta access to this powerful tool is usually challenging and restricted. However, with MedAssistant, we grant physicians free beta access, enabling them to harness the power of GPT-4. The model can assist physicians in analyzing medical records, generating reports, answering patients' queries, and much more, thereby enhancing healthcare delivery.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
