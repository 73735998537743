// OTPSentPage.js

import React from 'react';
import { useLocation } from 'react-router-dom';

const OTPSentPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');

    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">OTP Sent!</h2>
                </div>
                <div>
                    <p className="text-center text-xl text-gray-700">We've sent an OTP to <span className="font-bold">{email}</span>. Please check your email to continue.</p>
                </div>
            </div>
        </div>
    );
};

export default OTPSentPage;
