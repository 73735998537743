// OTPCallbackPage.js

import React, { useEffect } from 'react';

const OTPCallbackPage = () => {


    useEffect(() => {
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const accessToken = hashParams.get('access_token');

        if (accessToken) {
            localStorage.setItem('auth', accessToken);
            window.location.href = '/home'
        } else {
            // Redirect to login page if no access token is found
            // window.location.href = '/login'
        }
    }, []);

    return (
        <div className="flex items-center justify-center h-screen">
            <h1 className="text-xl text-gray-700">Redirecting...</h1>
        </div>
    );
};

export default OTPCallbackPage;
