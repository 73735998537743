import React, { useState } from 'react';
import axios from 'axios';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isOTP, setIsOTP] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/login', {
                email,
                password,
                isOTP,
            });

            console.log(response);
            if (!isOTP) {
                localStorage.setItem('auth', response?.data?.user?.session?.access_token);
                window.location.href = '/';
            } else {
                window.location.href = `/otp-sent?email=${email}`;
            }
        } catch (err) {
            setError(err.response.data.error);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen py-2 bg-gray-100 sm:px-6 lg:px-8">
            <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-none shadow-xl border border-blue-500">
                <h1 className="text-3xl font-extrabold text-center text-gray-900">Login to your account</h1>
                {error && <div className="text-red-500">{error.message}</div>}
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm space-y-4">
                        <div>
                            <input
                                type="email"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </div>
                        {!isOTP && (
                            <div>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        )}
                    </div>

                    <div className="text-center">Or</div>

                    <div className="flex items-center justify-center mt-6">
                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle" id="toggle" checked={isOTP} onChange={() => setIsOTP(!isOTP)} className={`toggle-checkbox absolute block w-6 h-6 rounded-full ${isOTP ? 'right-0 bg-blue-200' : 'bg-green-400'}  border-4  appearance-none cursor-pointer`} />
                            <label htmlFor="toggle" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                        <label htmlFor="toggle" className="text-sm text-gray-900">{isOTP && 'Sign in with Password'} {!isOTP && 'Sign in with Magic Link'}</label>
                    </div>

                    <div className="flex justify-center mt-6">
                        <button
                            type="submit"
                            className={`w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md ${(!email || (!password && !isOTP)) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
                            disabled={!email || (!password && !isOTP)}
                        >
                            Log in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
