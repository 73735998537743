import React, { useState } from 'react';
import axios from 'axios';

const SignupPage = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [employer, setEmployer] = useState('');
    const [subspecialty, setSubspecialty] = useState('');
    const [error, setError] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [validPassword, setValidPassword] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/signup', {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                employer,
                subspecialty
            });

            console.log(response);
            localStorage.setItem("auth", response?.data?.user?.session?.token);

            // Redirect user to home page
            window.location = "/home";
        } catch (err) {
            setError(err.response.data.error);
        }
    };

    const handleNextStep = () => {
        if (step === 1 && (!firstName || !lastName)) {
            return;
        } else if (step === 2 && (!employer || !subspecialty)) {
            return;
        } else if (step === 3 && (!email || !password || !validEmail || !validPassword)) {
            return;
        }

        setStep((prevStep) => prevStep + 1);
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Step 1: Personal Information</h2>
                        <p className="mt-2 text-sm text-gray-600">Tell us a bit about yourself.</p>
                        <div className="mt-8 space-y-6">
                            <div>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Step 2: Professional Information</h2>
                        <p className="mt-2 text-sm text-gray-600">Help us understand your professional background.</p>
                        <div className="mt-8 space-y-6">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Employer"
                                    value={employer}
                                    onChange={(e) => setEmployer(e.target.value)}
                                    className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Subspecialty"
                                    value={subspecialty}
                                    onChange={(e) => setSubspecialty(e.target.value)}
                                    className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Step 3: Account Details</h2>
                        <p className="mt-2 text-sm text-gray-600">Create your account.</p>
                        <div className="mt-8 space-y-6">
                            <div>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={() => setValidEmail(validateEmail(email))}
                                    className={`appearance-none rounded-md block w-full px-3 py-2 border ${validEmail ? 'border-gray-300' : 'border-red-500'} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                                />
                                {!validEmail && <p className="mt-2 text-sm text-red-500">Please enter a valid email address.</p>}
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onBlur={() => setValidPassword(validatePassword(password))}
                                    className={`appearance-none rounded-md block w-full px-3 py-2 border ${validPassword ? 'border-gray-300' : 'border-red-500'} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                                />
                                {!validPassword && <p className="mt-2 text-sm text-red-500">Password must be at least 8 characters long.</p>}
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen py-2 bg-gray-100 sm:px-6 lg:px-8">
            <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-none shadow-xl border border-blue-500">
                <h1 className="text-3xl font-extrabold text-center text-gray-900">Create an Account</h1>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    {renderStep()}
                    <div>
                        <div className="flex justify-between">
                            {step > 1 && (
                                <button
                                    type="button"
                                    onClick={() => setStep((prevStep) => prevStep - 1)}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Previous
                                </button>
                            )}
                            {step < 3 ? (
                                <button
                                    type="button"
                                    onClick={handleNextStep}
                                    className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md ${step >= 0 ? 'hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500' : 'opacity-50 cursor-not-allowed'}`}
                                // disabled={step === 3 && (!email || !password || !validEmail || !validPassword)}
                                >
                                    Next
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md ${(!email || !password || !validEmail || !validPassword) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
                                    disabled={!email || !password || !validEmail || !validPassword}
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignupPage;
