import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage.js";
import LandingPage from "./pages/LandingPage.js";
import LoginPage from "./pages/LoginPage.js";
import SignupPage from "./pages/SignupPage.js";
import OTPSentPage from "./pages/OtpVerify.js";
import OTPCallbackPage from "./pages/OtpConfirm.js";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/home" element={<HomePage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/signup" element={<SignupPage />}></Route>
        <Route path="/otp-sent" element={<OTPSentPage />}></Route>
        <Route path="/otp-confirm" element={<OTPCallbackPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
